import AOS from 'aos';
import Cookies from 'js-cookie';
import 'owl.carousel';
import 'owl.carousel2.thumbs';
import 'public/e_formula/js/header.js';

require('datatables.net-bs4')

window.AOS = AOS;
window.Cookies = Cookies;

// JavaScript
$(document).ready( function () {
  isIE();
  var indexCoolie = Cookies.get('page_index');
  if (!indexCoolie) {
    $(".index-loader-container").show();
  }

  AOS.init();
  $("body").attr("data-index-cookie", Cookies.get('page_index'));
  // news table responsive
  $('.custom-editor').find('table').wrap('<div class="table-responsive"></div>');

  $(".investor a[href^='#']").click(function(e) {
    var position = $($(this).attr("href")).offset().top;
    $("body, html").animate({
      scrollTop: position
    }  );
  });
});

$(window).on("load", function () {
  var indexCoolie = Cookies.get('page_index');
  if (indexCoolie) {
    $("[data-page=index]").addClass("ani-show");
    $(".loader-container").fadeOut("slow");
  }else {
    setTimeout(() => {
      $(".loader-container").fadeOut("slow");
      $("[data-page=index]").addClass("ani-show");
    }, 600);
    Cookies.set('page_index', true, { expires: 0.14 });
  }
});

$(window).on("resize", function() {
  setTimeout(() => {
    AOS.refresh();
  }, 3000);
});

$.fn.visible = function (partial) {
  var $t = $(this),
    $w = $(window),
    $m = ($(window).height() - $t.height())/3,
    viewTop = $w.scrollTop(),
    viewBottom = viewTop + $w.height(),
    _top = $t.offset().top,
    _bottom = _top + $t.height(),
    compareTop = partial === true ? _bottom : _top,
    compareBottom = partial === true ? _top : _bottom;

  return ((compareBottom <= viewBottom - $m) && (compareTop >= viewTop + ($m * 0.5)));
}

$(window).scroll(function (e) {
  checkVisible();
});


function checkVisible() {
  $('.milestone-item').each(function (i, k) {
    if ($(this).visible()) {
      $(k).addClass('item-focus');
    } else {
      $(k).removeClass('item-focus');
    }
  });
}

function isIE() { //ie?
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    $("body").addClass('isIE');
  } else {
    $("body").removeClass('isIE');
  }
}
