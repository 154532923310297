// 下拉選單
$(function () {
  $('#mobileMenuBtn').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu').toggleClass('active');
  });

  var pageGroup = $(".frame").data("group");
  var pageName = $(".frame").data("page");
  var windowWidth = window.innerWidth;
  var windowHeight = $(window).height();
  var frameHeight = $(".frame").outerHeight();

  $('body').attr("data-body-group", pageGroup);
  var ActiveGroup = $("[data-menu=" + pageGroup +"]");
  ActiveGroup.toggleClass('active');

  if (pageGroup) {
    ActiveGroup.find(".header-menu-item-group").addClass('group-active');
    ActiveGroup.find(".item-group-link[data-menu-link=" + pageName +"]").addClass('active');
  }else {
    $('.header-menu-item').hover(function() {
      setTimeout(() => {
        $(this).toggleClass('active');
        $(this).find('.header-menu-item-group').toggleClass('group-active');
      }, 100);
    });
  };

  $("#scroll-to-top").on('click', function() {
    $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
  });

  $(window).scroll(function () {
    if (pageName == "index") {
      if ($(window).scrollTop() > (windowWidth / 32)) {
        $("#header").removeClass("index-header");
      } else {
        $("#header").addClass("index-header");
      }
    };
    if (pageGroup == "products") {
      let productMenu = $(".header-menu-item[data-menu=products]");
      if ($(window).scrollTop() > 50) {
        productMenu.removeClass("active");
        productMenu.find(".header-menu-item-group").removeClass("active");
      } else {
        productMenu.addClass("active");
        productMenu.find(".header-menu-item-group").addClass("active");
      }
    }
    if ($(window).scrollTop() > windowHeight/2) {
      $("#scroll-to-top").addClass('show');
      if ($(window).scrollTop() > (frameHeight - windowHeight)) {
        $("#scroll-to-top").addClass('fixed');
      }else {
        $("#scroll-to-top").removeClass('fixed');
      }
    }else {
      $("#scroll-to-top").removeClass('show');
    }
  });
});

